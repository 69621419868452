<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-12 p-md-4">
      <Fieldset legend="ตั้งค่ารหัสผ่านใหม่">
        <form @submit.prevent="handleResetPassword">
          <!-- รหัสผ่านใหม่ -->
          <div class="p-grid">
            <div class="p-col-12 p-md-4 p-lg-4">
              <label for="passwordNew" class="p-col-fixed">รหัสผ่านใหม่</label>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <Password
                id="passwordNew"
                v-model="forms.userPasswordNew"
                placeholder="รหัสผ่านใหม่"
                :class="[validates['userPasswordNew']]"
                toggleMask
              >
                <template #header>
                  <h6>ใส่รหัสผ่าน</h6>
                </template>
                <template #footer>
                  <Divider />
                  <p class="p-mt-2">ข้อเสนอแนะ</p>
                  <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                    <li>ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว</li>
                    <li>ตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว</li>
                    <li>ตัวเลขอย่างน้อยหนึ่งตัว</li>
                    <li>ขั้นต่ำ 8 อักขระ</li>
                  </ul>
                </template>
              </Password>
            </div>
          </div>
          <!--/รหัสผ่านใหม่ -->

          <!-- รหัสผ่านใหม่ อีกครั้ง -->
          <div class="p-grid">
            <div class="p-col-12 p-md-4 p-lg-4">
              <label for="passwordNewConf" class="p-col-fixed"
                >รหัสผ่านใหม่ อีกครั้ง
              </label>
            </div>
            <div class="p-col-12 p-md-4 p-lg-4">
              <Password
                id="userPasswordNewConf"
                v-model="forms.userPasswordNewConf"
                placeholder="รหัสผ่านใหม่ อีกครั้ง"
                :class="[validates['userPasswordNewConf']]"
                toggleMask
              >
                <template #header>
                  <h6>ใส่รหัสผ่าน</h6>
                </template>
                <template #footer>
                  <Divider />
                  <p class="p-mt-2">ข้อเสนอแนะ</p>
                  <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                    <li>ตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว</li>
                    <li>ตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว</li>
                    <li>ตัวเลขอย่างน้อยหนึ่งตัว</li>
                    <li>ขั้นต่ำ 8 อักขระ</li>
                  </ul>
                </template>
              </Password>
            </div>
          </div>
          <!--/รหัสผ่านใหม่ อีกครั้ง -->

          <div class="p-d-flex p-jc-center">
            <Button
              label="ยืนยันการตั้งค่ารหัสผ่านใหม่"
              icon="pi pi-check"
              iconPos="left"
              type="submit"
              class="p-button-lg p-button-success"
            />
          </div>
        </form>
      </Fieldset>
    </div>
  </div>

  <Dialog
    header="สถานะการทำรายการ กู้คืนรหัสผ่าน"
    v-model:visible="dialog.isOpen"
    :class="['p-dialog-responsive']"
    :style="{ width: '50vw' }"
  >
    <p>
      เปลี่ยนรหัสผ่านสำเร็จ ระบบกำลังพาคุณไปหน้า เข้าใช้งานระบบ
      คุณสามารถใช้รหัสผ่านที่ได้ทำการสร้างใหม่ในการเข้าใช้งานระบบครั้งนี้ได้ทันที
    </p>
    <template #footer>
      <Button
        label="ไปหน้าเข้าใช้งานระบบ"
        icon="pi pi-sign-in"
        type="button"
        class="p-button-success"
        @click="() => $router.push({ path: '/login' })"
      />
      <Button
        label="ปิด"
        icon="pi pi-exit"
        type="button"
        class="p-button-link"
        @click="() => (dialog.isOpen = false)"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'resetPassword',
  layout: 'Public',
  data() {
    return {
      forms: {
        secureToken: '',
        userPasswordNew: null,
        userPasswordNewConf: null
      },
      dialog: {
        isOpen: false,
        message: ''
      }
    }
  },
  computed: {
    validates() {
      const fields = Object.keys(this.forms).reduce((fields, value) => {
        const _value = this.forms[[value]]
        return {
          ...fields,
          [value]: _value != null && _value != '' ? '' : 'p-invalid'
        }
      }, {})
      console.log('fields ::==', fields)
      return fields
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { secure_token } = this.$route.query
      console.log('secure_token ::=', secure_token)
      this.forms.secureToken = secure_token
    })
  },
  methods: {
    async handleResetPassword() {
      const { userPasswordNew, userPasswordNewConf } = this.forms
      if (
        !userPasswordNew ||
        userPasswordNew == '' ||
        !userPasswordNewConf ||
        userPasswordNewConf == ''
      ) {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail:
            'กรุณากรอก รหัสผ่านใหม่ และ ยืนยัน รหัสผ่านใหม่ ก่อนยืนยันการทำรายการ',
          life: 3000
        })
      } else {
        const { status, code, data, invalidCode } = await this.$store.dispatch(
          'user/resetPasswordFromMail',
          this.forms
        )
        console.log('status, code, data ::== ', status, code, data)
        if (code == 200) {
          this.dialog.isOpen = true
        } else {
          /*
           * 101 = tokencode not found
           * 102 = token has expired
           * 103 = update fail
           */
          let message = 'เปลี่ยนรหัสผ่านของคุณ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ'
          if ('101' == invalidCode) {
            message = 'ไม่พบข้อมูลการขอเปลี่ยนรหัสผ่าน ของคุณในระบบ'
          } else if ('102' == invalidCode) {
            message = 'หมดเวลาการขอเปลี่ยนรหัสผ่าน'
          }
          this.$toast.add({
            severity: 'error',
            summary: 'สถานะการทำรายการ',
            detail: message,
            life: 3000
          })
        }
      }
    }
  }
}
</script>

<style>
</style>